'use strict';

/*global define, ga, respimage*/

define('Common', ['jquery', 'fastclick', 'respimage' ,'jquery.smooth-scroll', 'jquery.easing'], function ($, FastClick) {
    var Common = {
        __data: {
            elements: {
                $siteLogo: $('.logo-pixelfordinner-stroke'),
                $parallax: []
            },
            selectors: {
                siteLoader: '.logo-pixelfordinner-stroke.is-animating',
                parallax: '[data-parallax]'
            },
            vars: {
                parallax: {
                    divider: 1.5,
                    listening: false
                }
            }
        },

        init: function () {
            Common._bindings();
            Common._initFastClick();
            Common._findParallaxElements();
        },

        _bindings: function () {
            // --
            // Event Trigger
            // --
            $('[data-trigger-target]').each(function (i, el) {
                $(el).click(function () {
                    var $this = $(this),
                        $triggerTarget = $($this.data('trigger-target')),
                        triggerEvent = $this.data('trigger-event');

                    if (typeof triggerEvent !== undefined && $triggerTarget.length > 0) {
                        $triggerTarget.trigger(triggerEvent);
                    }

                });
            });

            // --
            // Class Toggle
            // --
            $('[data-toggle-target]').each(function (i, el) {
                $(el).click(function (e) {
                    var $this = $(this),
                        $toggleTarget = $($this.data('toggle-target')),
                        toggleClass = $this.data('toggle-class');

                    e.preventDefault();

                    if (typeof toggleClass !== undefined && $toggleTarget.length > 0) {
                        $toggleTarget.toggleClass(toggleClass);
                    }
                });
            });

            // --
            // Scroll Toggle
            // --
            $('[data-scroll-target]').each(function (i, el) {
                $(el).click(function (e) {
                    var $this = $(this),
                        $scrollTarget = $($this.data('scroll-target')),
                        scrollDuration = $this.data('scroll-duration') ? $this.data('scroll-duration') : 400;

                    e.preventDefault();

                    if (typeof $.fn.smoothScroll === 'function') {
                        $.smoothScroll({
                            speed: scrollDuration,
                            easing: 'easeInOutCubic',
                            scrollTarget: $scrollTarget
                        });
                    }
                });
            });
        },

        _createPictureElement: function () {
            document.createElement('picture');
        },

        _initFastClick: function () {
            // --
            // Fastclick init
            // --
            new FastClick(document.body);
        },

        _findParallaxElements: function () {
            $(window).off('scroll', Common._updateParallax);
            Common.__data.elements.$parallax = $(Common.__data.selectors.parallax);

            if (Common.__data.elements.$parallax.length) {
                Common._initParallax();
            }
        },

        _initParallax: function () {
            Common.__data.elements.$parallax.each(function() {
                $(window).on('scroll', null, $(this), Common._updateParallax);
            });
        },

        _updateParallax: function(e) {
            e.data.css({
                transform: 'translate3d(0, ' + window.scrollY / Common.__data.vars.parallax.divider + 'px, 0)'
            });
        },

        _scrollTo: function (el, beforeCallback, afterCallback) {
            if (typeof $.fn.smoothScroll === 'undefined') {
                return;
            }

            $.smoothScroll({
                speed: 300,
                easing: 'easeInOutCubic',
                scrollTarget: el,
                beforeScroll: beforeCallback,
                afterScroll: afterCallback
            });
        },

        _appendSpinner: function ($el) {
            var $loader = Common.__data.elements.$siteLogo.clone(),
                baseClasses = $loader.attr('class') + ' is-animating is-centered';

            $loader.attr('class', baseClasses + ' is-invisible');
            $el.append($loader);
            $loader.attr('class', baseClasses);
        },

        _removeSpinner: function ($el) {
            $el.find(Common.__data.selectors.siteLoader).remove();
        },

        _sendGAEvent: function (category, eventName) {
            if (typeof ga !== 'function') {
                return;
            }

            ga('send', 'event', category, eventName);
        },

        _sendGAPageView: function (url) {
            if (typeof ga !== 'function') {
                return;
            }

            ga('send', 'pageview', url);
        },

        _reloadRespImage: function() {
            if( window.respimage ) {
                respimage();
            }
        },

        __xhrRequest: function (url, callbackSuccess, callbackError)  {
            $.ajax(url, {
                data: { ajax: true },
                success: callbackSuccess,
                error: callbackError
            });
        }
    };

    return Common;
});

