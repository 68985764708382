    'use strict';

require.config({
    paths: {
        jquery: 'vendor/jquery/dist/jquery',
        requirejs: 'vendor/requirejs/require',
        Assets: 'modules/assets',
        Common: 'modules/common',
        Header: 'modules/header',
        Agency: 'modules/agency',
        Contact: 'modules/contact',
        Projects: 'modules/projects',
        Project: 'modules/project',
        headroom: 'vendor/headroom.js/dist/headroom',
        'slick-carousel': 'vendor/slick-carousel/slick/slick.min',
        'jquery-knob': 'vendor/jquery-knob/js/jquery.knob',
        'jquery.stellar': 'vendor/jquery.stellar/jquery.stellar',
        'jquery.smooth-scroll': 'vendor/jquery.smooth-scroll/jquery.smooth-scroll',
        'jquery.easing': 'vendor/jquery-easing/jquery.easing.min',
        history: 'vendor/history.js/scripts/bundled/html5/native.history',
        jStorage: 'vendor/jStorage/jstorage',
        fastclick: 'vendor/fastclick/lib/fastclick',
        respimage: 'vendor/respimage/respimage.min',
        'jQuery.headroom': 'vendor/headroom.js/dist/jQuery.headroom',
        'angular.headroom': 'vendor/headroom.js/dist/angular.headroom',
        imagesloaded: 'vendor/imagesloaded/imagesloaded',
        isotope: 'vendor/isotope/js/isotope',
        outlayer: 'vendor/outlayer',
        eventEmitter: 'vendor/eventEmitter',
        eventie: 'vendor/eventie',
        'get-size': 'vendor/get-size',
        'matches-selector': 'vendor/matches-selector',
        masonry: 'vendor/masonry/masonry',
        'get-style-property': 'vendor/get-style-property',
        'doc-ready': 'vendor/doc-ready'
    },
    shim: {
        'slick-carousel': {
            deps: [
                'jquery'
            ]
        },
        'jquery-knob': {
            deps: [
                'jquery'
            ]
        },
        'jQuery.headroom': {
            deps: [
                'jquery',
                'headroom'
            ]
        },
        isotope: {
            deps: [
                'jquery'
            ]
        }
    },
    packages: [

    ]
});

require(['app', 'requirejs']);

define("main", function(){});

