'use strict';

require(['jquery', 'Common', 'Header', 'Agency', 'Projects', 'Project', 'Contact', 'Assets'], function ($, Common, Header, Agency, Projects, Project, Contact, Assets) {
    var website = {
        __data: {
            modules: [
                Assets,
                Common,
                Header,
                Agency,
                Projects,
                Project,
                Contact
            ]
        },

        init: function () {
            website._initModules();
        },

        _initModules: function () {
            $(website.__data.modules).each(function (i, module) {
                module.init();
            });
        }

    };

    window._website = website;

    $(document).ready(website.init());
});

define("app", function(){});

