'use strict';

/*global define, _siteData*/

define('Assets', [], function () {
    var Assets = {
        __data: {
            myFonts: {
                id: '2c87d3'
            },
            googleFonts: {
                families: 'Open+Sans:300:latin'
            }
        },

        init: function () {
            Assets._googleFonts();
            Assets._gruntIcons();
            setTimeout(Assets._myFonts, 500);
            setTimeout(Assets._inactiveTimeout, 1500);
        },

        _googleFonts: function () {
            window.WebFontConfig = {
                google: {
                    families: [Assets.__data.googleFonts.families]
                },
                custom : {
                    families: ['HurmeGeometricSansNo3:n3,n7'],
                    urls: [_siteData.paths.styles + '/css/fonts.css']
                }
            };

            (function() {
                var wf = document.createElement('script');
                wf.src = ('https:' === document.location.protocol ? 'https' : 'http') +
                    '://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
                wf.type = 'text/javascript';
                wf.async = 'true';
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(wf, s);
            })();
        },

        _myFonts: function() {
            (function() {
                var wf = document.createElement('script');
                wf.src = ('https:' === document.location.protocol ? 'https' : 'http') +
                    '://hello.myfonts.net/count/' + Assets.__data.myFonts.id;
                wf.type = 'text/javascript';
                wf.async = 'true';
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(wf, s);
            })();
        },

        _inactiveTimeout: function() {
            var $html = $('html');

            if ($html.hasClass('wf-active') || $html.hasClass('wf-inactive')) {
                return;
            }
            $html.addClass('wf-inactive');
        },

        _gruntIcons: function() {
            /* jshint ignore:start */
            var grunticon = function(e) {
                if (e && 3 === e.length) {
                    var t = window,
                        n = !(!t.document.createElementNS || !t.document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect || !document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1') || window.opera && -1 === navigator.userAgent.indexOf('Chrome')),
                        o = function(o) {
                            var a = t.document.createElement('link'),
                                r = t.document.getElementsByTagName('script')[0];
                            a.rel = 'stylesheet', a.href = e[o && n ? 0 : o ? 1 : 2], a.media = 'only x', r.parentNode.insertBefore(a, r), setTimeout(function() {
                                a.media = 'all'
                            })
                        },
                        a = new t.Image;
                    a.onerror = function() {
                        o(!1)
                    }, a.onload = function() {
                        o(1 === a.width && 1 === a.height)
                    }, a.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=='
                }
            };

            grunticon([
                _siteData.paths.icons + '/icons.data.svg.css',
                _siteData.paths.icons + '/icons.data.png.css',
                _siteData.paths.icons + '/icons.fallback.css'
            ]);
            /* jshint ignore:end */
        }

    };

    return Assets;
});

