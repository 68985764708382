'use strict';

/*global define,google*/

define('Contact', ['jquery'], function ($) {
    var Contact = {
        __data: {
            elements: {
                map: $('.contact__map')
            }
        },

        init: function () {
            if (Contact.__data.elements.map.length > 0 &&
                typeof google === 'object' &&
                typeof google.maps === 'object') {
                this._initModuleGoogleMap();
            }
        },

        _initModuleGoogleMap: function() {
            var lat = Contact.__data.elements.map.data('coord-lat'),
                lng = Contact.__data.elements.map.data('coord-lng'),
                pin = Contact.__data.elements.map.data('coord-pin');

            var gmapCoords = new google.maps.LatLng(lat, lng),

                gmapSettings = {
                    zoom: 15,
                    center: gmapCoords,
                    scrollwheel: false,
                    draggable: true,
                    disableDefaultUI: true,
                    styles: [{
                      stylers: [{
                        'invert_lightness': !0
                      }, {
                        saturation: -100
                      }]
                    }, {
                      featureType: 'administrative.country',
                      elementType: 'labels.text',
                      stylers: [{
                        visibility: 'on'
                      }, {
                        'invert_lightness': !0
                      }, {
                        saturation: -3
                      }, {
                        lightness: -57
                      }]
                    }, {
                      featureType: 'administrative.locality',
                      stylers: [{
                        visibility: 'on'
                      }, {
                        'invert_lightness': !0
                      }, {
                        saturation: -3
                      }, {
                        lightness: -57
                      }]
                    }, {
                      featureType: 'administrative.province',
                      elementType: 'geometry.stroke',
                      stylers: [{
                        visibility: 'off'
                      }]
                    }, {
                      featureType: 'administrative.country',
                      elementType: 'geometry.stroke',
                      stylers: [{
                        lightness: -2
                      }, {
                        visibility: 'on'
                      }, {
                        weight: 0.5
                      }, {
                        gamma: 0.56
                      }]
                    }, {
                      featureType: 'administrative.province',
                      elementType: 'labels.text',
                      stylers: [{
                        visibility: 'on'
                      }, {
                        lightness: -19
                      }, {
                        gamma: 0.6
                      }]
                    }, {
                      featureType: 'administrative.province',
                      elementType: 'geometry.stroke',
                      stylers: [{
                        visibility: 'on'
                      }, {
                        gamma: 0.85
                      }]
                    }, {
                      featureType: 'water',
                      elementType: 'geometry',
                      stylers: [{
                        gamma: 0.38
                      }],
                    },
                    {
                      featureType: 'poi',
                      stylers: [
                      { visibility: 'off' }
                    ]
                    }],

                },

                gmapElement = new google.maps.Map(Contact.__data.elements.map.get(0), gmapSettings);

                new google.maps.Marker({
                  draggable: false,
                  raiseOnDrag: false,
                  map: gmapElement,
                  icon: pin,
                  position: gmapCoords,
                  title: 'Pixelfordinner'
                });
        }
    };

    return Contact;
});

