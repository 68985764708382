'use strict';

/*global define*/

define('Header', ['jquery', 'jQuery.headroom'], function ($) {
    var Header = {
        __data: {
            elements: {
                header: $('.header')
            }
        },

        init: function () {
            this._bindings();
        },

        _bindings: function () {
            if (Header.__data.elements.header.length > 0 && typeof $.fn.headroom === 'function') {
                Header.__data.elements.header.headroom({
                    offset : 32,
                    tolerance : 8,
                    classes : {
                        initial : 'header--fixed',
                        pinned : 'header--pinned',
                        unpinned : 'header--unpinned',
                        top : 'header--top',
                        notTop : 'header--not-top'
                    }
                });
            }
        }
    };

    return Header;
});

