'use strict';

    /*global define, History*/

    define('Project', ['jquery', 'imagesloaded', 'Common', 'jStorage', 'history'], function ($, imagesLoaded, Common) {
        var Project = {
            __data: {
                elements: {
                    $projectDetails: $('.project__details__section--header'),
                    $projectContent: $('.project__details__section--content')
                },
                selectors: {
                    navigationItem: '[data-project-load]',
                    projectDetails: '.project__details__section--header',
                    projectContent: '.project__details__section--content',
                    projectCover: '.cover-picture'
                },
                states: {
                    isUnloaded: false
                },
                objects: {
                    pageCache: {}
                }
            },

            init: function () {
                Project._loadPageCache();
                Project._waitForPageLoad();
                Project._bindEvents();
            },

            _waitForPageLoad: function () {
                if (Project.__data.elements.$projectDetails.length > 0) {
                    Common._appendSpinner($('body'));
                    Project._waitForCoverImage(function() { Project._showContent(false); });
                }
            },

            _bindEvents: function () {
                // Bind Project Navigation

                if (Project.__data.elements.$projectDetails.length > 0) {
                    Project._bindProjectNavigation();
                    Project._bindHistory();
                }
            },

            _bindHistory: function() {
                // History related init & events
                if (typeof History !== 'undefined') {
                    // Set the initial state with current content
                    Project._setInitialState();

                    // Bind state change events.
                    History.Adapter.bind(window, 'statechange', function() {
                        var _afterUnload = function () {},
                            url = window.location.pathname;

                        if (typeof Project.__data.objects.pageCache[url] !== 'undefined') {
                            _afterUnload = function () {
                                Project._updateContent(Project.__data.objects.pageCache[url]);
                                Common._reloadRespImage();

                                // https://gist.github.com/jlong/2428561
                                var parser = document.createElement('a');
                                parser.href = url;
                                Common._sendGAPageView(parser.pathname);
                            };
                        } else {
                            _afterUnload = function () {
                                Project._fetchContent(url);
                            };
                        }
                            Project._unloadContent(_afterUnload);
                    });
                }
            },

            _bindProjectNavigation: function () {
                var $items = $(Project.__data.selectors.navigationItem);

                $items.click(function (e) {
                    e.preventDefault();

                    var $this = $(this),
                        url = $this.attr('href'),
                        eventName = $this.data('project-load');

                    Common._sendGAEvent('project', eventName);

                    if (typeof History !== 'undefined') {
                        History.pushState({}, null, url);
                    }
                });
            },

            _setInitialState: function () {
                var pageTitle = $('title').text(),
                    page = {
                        title: pageTitle,
                        details: $(Project.__data.selectors.projectDetails).html(),
                        content: $(Project.__data.selectors.projectContent).html()
                    };

                Project.__data.objects.pageCache[window.location.pathname] = page;
                Project._savePageCache();
            },

            _fetchContent: function (url) {
                var callbackSuccess = function (data) {
                    var page = Project._parseContent(url, data);
                    Project._updateState(page);
                };

                var callbackError = function () {
                    window.location = url;
                };

                Common.__xhrRequest(url, callbackSuccess, callbackError);
            },



            _parseContent: function (url, data) {
                var $data = $(data);

                var page = {
                    title: $data.filter('title').text(),
                    details: $data.filter(Project.__data.selectors.projectDetails).html(),
                    content: $data.filter(Project.__data.selectors.projectContent).html()
                };

                return page;
            },

            _updateState: function (page) {
                if (typeof History !== 'undefined') {
                    Project.__data.objects.pageCache[window.location.pathname] = page;
                    Project._savePageCache();
                    History.replaceState({}, page.title, window.location.pathname);
                }
            },

            _updateContent: function (page) {
                Project.__data.elements.$projectDetails.html(page.details);
                Project.__data.elements.$projectContent.html(page.content);
                $('title').text(page.title);


                Project._waitForCoverImage(function() { Project._showContent(true); });
            },

            _waitForCoverImage: function (callback) {
                var $projectCover = Project.__data.elements.$projectDetails
                                        .find(Project.__data.selectors.projectCover);

                var image = $projectCover.css('background-image').match(/url\((['"])?(.*?)\1\)/),
                    $img = $('<img>').attr('src', image.pop()),

                imgLoad = imagesLoaded($img.get(0));

                imgLoad.on('always', callback);
            },

            _unloadContent: function (afterCallback, beforeCallback) {
                var _beforeScroll = function () {
                        Project.__data.elements.$projectDetails.addClass('is-loading');

                        if (typeof beforeCallback === 'function') {
                            beforeCallback();
                        }
                    },
                    _afterScroll = function () {
                        var $body = $('body');

                        $body.css('overflow', 'hidden');
                        Common._appendSpinner($body);
                        Project.__data.elements.$projectContent.empty();
                        Project.__data.states.isUnloaded = true;

                        if (typeof afterCallback === 'function') {
                            afterCallback();
                        }
                    };

                if (Project.__data.states.isUnloaded === true) {
                    if (typeof beforeCallback === 'function') {
                        beforeCallback();
                    }

                    if (typeof afterCallback === 'function') {
                        afterCallback();
                    }
                } else {
                    Common._scrollTo(window, _beforeScroll, _afterScroll);
                }
            },

            _showContent: function (reloadNeeded) {
                var $body = $('body');

                $body.css('overflow', 'visible');
                Common._removeSpinner($body);
                Project.__data.elements.$projectDetails.removeClass('is-loading');

                if (reloadNeeded === true) {
                    Project._bindProjectNavigation();
                    Common._bindings();
                    Common._findParallaxElements();
                    Project.__data.states.isUnloaded = false;
                }
            },

            _loadPageCache: function () {
                Project.__data.objects.pageCache = $.jStorage.get('Project', {});
            },

            _savePageCache: function() {
                $.jStorage.set('Project', Project.__data.objects.pageCache, {TTL: 3600000});
            }
        };

        return Project;
    });

