'use strict';

/*global define*/

define('Agency', ['jquery', 'slick-carousel', 'jquery-knob'], function ($) {
    var Agency = {
        __data: {
            numberOfSlides: 0,
            incrementStep: 60,
            degrees: [60, 120, 180, 240, 300, 360],

            elements: {
                $slider: $('.p4d__360__slider'),
                $knob: $('.p4d__360__knob__input'),
                $numbers: $('.p4d__360__knob__numbers')
            }
        },

        init: function () {
            this._bindings();
        },

        _bindings: function () {
            if (Agency.__data.elements.$slider.length > 0) {
                Agency._initModuleSlider();
            }

            if (Agency.__data.elements.$knob.length > 0) {
                Agency._initModuleKnob();
            }
        },

        _initModuleSlider: function () {
            if (typeof $.fn.slick !== 'function') {
                return;
            }

            Agency.__data.elements.$slider.slick({
                slide: '.p4d__360__slider__slide',
                draggable: true,
                speed: 300,
                autoplay: true,
                autoplaySpeed: 4000,

                onInit: function(slider) {
                    Agency._moduleUpdateNumbers(Agency.__data.degrees[0]);

                    $(slider.$slides.get(0))
                        .find('.p4d__360__skill__list')
                        .addClass('is-active');
                },

                onBeforeChange: function (slider, currentSlide, targetSlide) {
                    var currentValue = parseInt(Agency.__data.elements.$knob.val());
                    var targetValue = Agency.__data.degrees[targetSlide];

                    $(slider.$slides.get(currentSlide))
                        .find('.p4d__360__skill__list')
                        .removeClass('is-active');

                    $(slider.$slides.get(targetSlide))
                        .find('.p4d__360__skill__list')
                        .addClass('is-active');

                    $({degrees: currentValue}).animate(
                        {
                            degrees: targetValue
                        },
                        {
                            duration: 300,
                            step: function () {
                                var animatingValue = Math.round(this.degrees);
                                Agency._moduleUpdateNumbers(animatingValue);
                            }
                        }
                     );
                }
            });
        },

        _initModuleKnob: function() {
            if (typeof $.fn.knob !== 'function') {
                return;
            }

            Agency.__data.elements.$knob.knob();
        },

        _moduleUpdateNumbers: function(value) {
            Agency.__data.elements.$knob
                .val(value)
                .trigger('change');

            Agency.__data.elements.$numbers
                .get(0)
                .innerHTML = value;
        }
    };

    return Agency;
});

