'use strict';

/*global define*/

define('Projects', ['jquery', 'isotope', 'imagesloaded'], function ($, Isotope, imagesLoaded) {
    var Projects = {
        __data: {
            elements: {
                $grid : $('.project__grid'),
                $filterContainer: $('.project__filters__container'),
                $headerFiltersToggle: $('.header__filters__toggle')
            },
            selectors: {
                gridItem: '.project__grid__item',
                filterItem: '[data-filter]'
            },
            objects: {
                isotope: null
            }
        },

        init: function () {
            if (Projects.__data.elements.$grid.length > 0) {
                Projects._waitForGridImages();
            }

            if (Projects.__data.elements.$filterContainer.length > 0) {
                Projects._bindFilters();
            }
        },

        _waitForGridImages: function () {
            var imgLoad = imagesLoaded(Projects.__data.elements.$grid);

            imgLoad.on('always', function() { Projects._initModuleIsotope(); });
            imgLoad.on('progress', function (instance, image) {
                $(image.img)
                    .parents(Projects.__data.selectors.gridItem)
                        .addClass('is-loaded');
            });
        },

        _initModuleIsotope: function () {
            Projects.__data.objects.isotope = new Isotope(
                Projects.__data.elements.$grid.get(0), {
                    itemSelector: Projects.__data.selectors.gridItem,

                    masonry: {
                        columnWidth: Projects.__data.selectors.gridItem
                }
            });
        },

        _bindFilters: function () {
            var $filters = $(Projects.__data.selectors.filterItem);

            $filters.each(function (i, el) {
                $(el).click(function (e) {
                    e.preventDefault();

                    if (typeof Projects.__data.objects.isotope === 'object') {
                        Projects.__data.objects.isotope
                            .arrange({
                                filter: $(this).data('filter')
                            });
                        Projects.__data.objects.isotope
                            .arrange();
                        $(this).addClass('is-active').siblings().removeClass('is-active');
                        Projects.__data.elements.$headerFiltersToggle.trigger('click');

                    }
                });
            });
        }
    };

    return Projects;
});

